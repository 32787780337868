import React, { useState, useEffect } from 'react';
import ENDPOINT from '../../constants/api-endpoints';
import LoadingSpinner from "../elements/LoadingSpinner";
import { Link, useParams } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.css";
var parse = require('html-react-parser');

const EducationListStateWise = () => {
    let { slug } = useParams();
    // let initialState = {
    //     site:ENDPOINT.SITE_ID
    // };

    const [isLoading, setIsLoading] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [msgColor, setMsgColor] = useState('');
    let [pageData, setPage] = useState([]);
    useEffect((slug) => {
        fetchPageData(slug);
    }, [slug]);
    const fetchPageData = () => {
        setIsLoading(true);
        fetch(ENDPOINT.EDUCATION_STATEWISE_DATA + '/' + ENDPOINT.SITE_ID + '/' + slug, {
            method: 'GET',
            //body: JSON.stringify(initialState),
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === 'error') {
                setMsgColor('red');
                setResMsg(responseData.message);
                setIsLoading(false);
            } else {
                setPage(responseData);
                setIsLoading(false);
            }
        })
        .catch((err) => {
            setMsgColor('red');
            setResMsg(err.message);
            setIsLoading(false);
        });
    };
    let pageContent = '';
    let pageStates = '';
    if (pageData.length !== 0) {
        pageStates = pageData.states;
        pageContent = pageData.schools;
    }  
    const renderData = (
        <>
            <section className="full_width  d-flex justify-content-center align-items-center">
                <div className="container innerpage">
                    <h1>Educations Institutes - State wise</h1>
                    <div className="row">
                        <div className="col-3">
                            <ul className="socialLinks">                    
                            {pageStates ?
                                pageStates.map((item,i) => (
                                    <li key={i}><Link className={`mb-2 btn btn-sm ${(slug==item.slug) ? 'btn-info' : 'btn-warning'}`} to={`/educations/${item.slug}`}>{item.name}</Link></li>                        
                                )) : 
                                    `No Record Found!!`
                            }
                            </ul>                             
                        </div>
                        <div className="col-9">                            
                            <ul className="eduContent">
                                {pageContent ?
                                    pageContent.map((item,i) => (
                                        <li key={i}><Link to={`/educations/${item.state}/${item.slug}`}>{item.name}</Link></li>                        
                                    )) : 
                                        `No Record Found!!`
                                } 
                            </ul>
                        </div>
                    </div>                    
                </div>
            </section>
        </>
    );
    return (
        <>
            {isLoading ? <LoadingSpinner /> : renderData}
        </>
    );
};
  
export default EducationListStateWise;
