import React, { useState, useEffect } from 'react';
import ENDPOINT from '../../constants/api-endpoints';
import LoadingSpinner from "../elements/LoadingSpinner";
import Accordion from 'react-bootstrap/Accordion';

import "bootstrap/dist/css/bootstrap.css";
var parse = require('html-react-parser');

const Sponsor = () => {
    let initialState = {
        site:ENDPOINT.SITE_ID
    };

    const [isLoading, setIsLoading] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [msgColor, setMsgColor] = useState('');
    let [pageData, setPage] = useState([]);
    useEffect(() => {
        fetchPageData();
    }, []);
    const fetchPageData = () => {
        setIsLoading(true);
        fetch(ENDPOINT.SPONSOR_DATA, {
            method: 'GET',
            // body: JSON.stringify(initialState),
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === 'error') {
                setMsgColor('red');
                setResMsg(responseData.message);
                setIsLoading(false);
            } else {
                setPage(responseData);
                setIsLoading(false);
            }
        })
        .catch((err) => {
            setMsgColor('red');
            setResMsg(err.message);
            setIsLoading(false);
        });
    };
    // let pageTitle = '';
    // let pageContent = '';
    // if (pageData.length !== 0) {
    //     pageTitle = pageData.data.title;
    //     pageContent = pageData.data.content;
    // }
    const renderData = (
        <>
            <section className="full_width  d-flex justify-content-center align-items-center">
                <div className="container innerpage">
                    <h1>Sponsors</h1>                    
                    <Accordion defaultActiveKey="0">
                    {pageData ?
                        pageData.map((item,i) => (
                            <Accordion.Item eventKey={i}>
                                <Accordion.Header>{item.title}</Accordion.Header>
                                <Accordion.Body>{parse(item.content)}</Accordion.Body>
                            </Accordion.Item>                            
                        )) : 
                            <p>No Record Found!!</p>
                    }
                    </Accordion>
                </div>
            </section>
        </>
    );
    return (
        <>
            {isLoading ? <LoadingSpinner /> : renderData}
        </>
    );
};
  
export default Sponsor;
