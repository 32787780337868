import React, { useState, useEffect } from 'react';
import ENDPOINT from '../../constants/api-endpoints';
import LoadingSpinner from "../elements/LoadingSpinner";
import { Link, useParams } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.css";
var parse = require('html-react-parser');

const EducationInstitute = () => {
    let { slug } = useParams();
    let { state } = useParams();
    // let initialState = {
    //     site:ENDPOINT.SITE_ID
    // };

    const [isLoading, setIsLoading] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const [msgColor, setMsgColor] = useState('');
    let [pageData, setPage] = useState([]);
    useEffect((slug) => {
        fetchPageData(slug);
    }, [slug]);
    const fetchPageData = () => {
        setIsLoading(true);
        fetch(ENDPOINT.EDUCATION_INSTITUTE_DATA + '/' + ENDPOINT.SITE_ID + '/' + slug, {
            method: 'GET',
            //body: JSON.stringify(initialState),
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status === 'error') {
                setMsgColor('red');
                setResMsg(responseData.message);
                setIsLoading(false);
            } else {
                setPage(responseData);
                setIsLoading(false);
            }
        })
        .catch((err) => {
            setMsgColor('red');
            setResMsg(err.message);
            setIsLoading(false);
        });
    };
    let insTitle = '';
    let insDesc = '';
    let insEmail = '';
    let insPhone = '';
    let insConsInfo = '';
    let insAddress = '';
    let insMap = '';
    let insLogo = '';
    let insGeneral = [];
    let insRelated = [];
    let insSocial = [];
    if (pageData.length !== 0) {
        insTitle = pageData.name;
        insDesc = pageData.description;
        insEmail = pageData.email;
        insPhone = pageData.phone;
        insConsInfo = pageData.consumer_info;
        insAddress = pageData.address;
        insMap = pageData.mapInfo;
        insLogo = pageData.logo;
        insGeneral = pageData.general;
        insRelated = pageData.related;
        insSocial = pageData.social;
    }  
    const renderData = (
        <>
            <section className="full_width  d-flex justify-content-center align-items-center">
                <div className="container innerpage">
                    <h1>{insTitle}</h1>
                    <div className="row">
                        <div className="col-3">                            
                           {insLogo && <p><img src={insLogo} alt={insTitle} title={insTitle} /></p>}
                           <p>
                                <ul className="socialLinks">
                                    {insSocial ?
                                        insSocial.map((item,i) => (                                            
                                            <li key={i}><a href={item.link} target="_blank"><i className={`fa ${(item.title == 'facebook') ? 'fa-facebook-square' : ''} 
                                            ${(item.title == 'twitter') ? 'fa-twitter-square' : ''}  
                                            ${(item.title == 'instagram') ? 'fa-instagram' : ''}  
                                            ${(item.title == 'google') ? 'fa-google' : ''}  
                                            ${(item.title == 'youtube') ? 'fa-youtube-square' : ''}  
                                            ${(item.title == 'linkedin') ? 'fa-linkedin-square' : ''} 
                                            fa-2x`} aria-hidden="true"></i></a></li>                   
                                        )) : 
                                            <li>`No Record Found!!`</li>
                                    }
                                </ul>
                           </p>
                           {insEmail && <p><strong>EMAIL:</strong> {insEmail}</p>}
                           {insPhone && <p><strong>PHONE:</strong> {insPhone}</p>}
                           {insAddress && <p>{parse(insAddress)}</p>}
                           {insMap && <p><iframe src={insMap} width="250" /></p>}
                        </div>
                        <div className="col-9">                            
                            <div className="mb-5">{parse(insDesc)}</div>
                            <div className="mb-5">
                                <h4>GENERAL INFORMATION</h4>
                                {insGeneral ?
                                    insGeneral.map((item,i) => (
                                        <li key={i}><a href={item.link}>{(item.title) ? item.title : item.link}</a></li>                        
                                    )) : 
                                        `No Record Found!!`
                                } 
                            </div>
                            <div className="mb-5">
                                <h4>CONSUMER INFORMATION</h4>
                                {parse(insConsInfo)}
                            </div>    
                            <div className="mb-5">
                                <h4>RELATED INFORMATION</h4>
                                {insRelated ?
                                    insRelated.map((item,i) => (
                                        <li key={i}><a href={item.link} target="_blank">{(item.title) ? item.title : item.link}</a></li>                        
                                    )) : 
                                        `No Record Found!!`
                                }
                            </div>                        
                        </div>
                    </div>                    
                </div>
            </section>
        </>
    );
    return (
        <>
            {isLoading ? <LoadingSpinner /> : renderData}
        </>
    );
};
  
export default EducationInstitute;
